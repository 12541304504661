<template>
    <div class="container">
        <div class="edit">
            <div class="header">
                <div class="left">
                    <span>{{editForm.id ? '编辑' : '新增'}}资源</span>
                </div>
                <div class="right">
                    <el-button round @click="handleCancel">取消</el-button>
                    <el-button type="primary" round @click="handleSave">保存</el-button>
                </div>
            </div>
            <div class="edit-container">
                <div class="edit-header">
                    <div class="floor" style="border-bottom: none;">
                        <div class="left">
                            <span>模版类型</span>
                            <el-select class="circular-bead-select" v-model="editForm.template_type"
                                placeholder="请选择" @change="handleTemplateTypeChange">
                                <el-option v-for="item of templateTypeList" :label="item.label" :value="item.value"
                                    :key="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="left">
                            <span>所属文件夹</span>
                            <!-- <el-cascader class="circular-bead-cascader" v-model="editForm.dirId" :props="dirProps" :show-all-levels="false"></el-cascader> -->
                            <el-cascader class="circular-bead-cascader" v-model="editForm.dirId" :options="dirTree"
                                clearable :props="dirProps"></el-cascader>
                        </div>
                    </div>
                    <div class="floor" style="border-bottom: none;border-radius: 0;border-top: none;">
                        <div class="left">
                            <span>资源名称</span>
                            <el-input class="circular-bead-input" style="width:250px;" placeholder="请输入资源名称"
                                v-model="editForm.name"></el-input>
                        </div>
                        <div class="left">
                            <span>主题名称</span>
                            <el-input class="circular-bead-input" style="width:250px;" placeholder="请输入资源名称"
                                v-model="editForm.topic"></el-input>
                        </div>
                    </div>
                    <div class="floor" style="border-radius: 0;border-top: none;">
                        <div class="left">
                            <span>资源类型</span>
                            <el-select class="circular-bead-select" v-model="editForm.type"
                                placeholder="请选择">
                                <el-option v-for="item of typeList" :label="item.label" :value="item.value"
                                    :key="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="right">
                            <span>资源标签</span>
                            <div :class="['tag', 'active']" v-for="(item, index) of editForm.labels" :key="index"
                                @click="handleRemoveLabel(index)">{{item}}
                            </div>
                            <el-button type="primary" icon="el-icon-plus" @click="handleAddLabel" class="add-tag-btn"></el-button>
                        </div>
                    </div>
                    <div class="floor" style="border-radius: 0;border-top: none;">
                        <div class="left">
                            <span>资源权重</span>
                            <el-input class="circular-bead-input" style="width:250px;" placeholder="请输入权重"
                                      v-model="editForm.sort"></el-input>
                        </div>
                    </div>
                </div>
                <div class="edit-content">
                    <resources-item :title="configItem.title" v-for="(configItem, index) of editForm.config"
                        :key="index">
                        <!-- 普通列表 -->
                        <div class="edit-ul" v-if="configItem.type === 'ul'">
                            <div class="edit-li" v-for="(item, i) of configItem.content" :key="i">
                                <div class="edit-li-no">{{i + 1}}</div>
                                <div class="edit-li-content">
                                    <el-input type="textarea" style="white-space: pre-wrap;" :autosize="{ minRows: 2}"
                                        v-model="item.value" placeholder="请输入内容">
                                    </el-input>
                                </div>
                                <div class="edit-li-del" @click="handleDeleteLi(configItem.content, i)">
                                    <div class="line"></div>
                                </div>
                            </div>
                            <div class="no plus" @click="handleLiAdd(configItem)">
                                <i class="el-icon-plus"></i>
                            </div>
                        </div>
                        <!-- 教学目标类列表 -->
                        <div class="edit-ul-jx" v-if="configItem.type === 'ul-jx'">
                            <div class="edit-li-jx" v-for="(item, i) of configItem.content" :key="i">
                                <div class="no arrow">0{{i + 1}}</div>
                                <div class="edit-li-jx-content">
                                    <div class="edit-li-jx-content-title">
                                        <div class="left">
                                            <el-input style="width: 70%;" v-model="item.title" placeholder="请输入标题">
                                            </el-input>
                                        </div>
                                        <div class="edit-li-del" @click="handleDeleteLiJx(configItem.content, i)">
                                            <div class="line"></div>
                                        </div>
                                    </div>
                                    <div class="edit-li-jx-item" v-for="(items, ii) of item.list" :key="ii">
                                        <div class="edit-li-jx-item-title">{{items.name}}</div>
                                        <div class="edit-li-jx-item-content">
                                            <el-input v-if="items.type === 'input'" style="white-space: pre-wrap;"
                                                placeholder="请输入内容" v-model="items.value" type="textarea"
                                                :autosize="{ minRows: 2}">
                                            </el-input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="edit-li-jx">
                                <div class="no plus" @click="handleAddLiJx(configItem.content)">
                                    <i class="el-icon-plus"></i>
                                </div>
                            </div>
                        </div>
                        <!-- 普通表单 -->
                        <div class="edit-form" v-if="configItem.type === 'form'">
                            <div class="edit-form-item" v-for="(item, i) of configItem.content" :key="i">
                                <div class="left">{{item.name}}</div>
                                <div class="right">
                                    <el-input class="circular-bead-input" v-if="item.type === 'input'"
                                        v-model="item.value" placeholder="请输入内容" style="width:300px;">
                                    </el-input>
                                    <div class="date-picker-group" v-if="item.type === 'date'">
                                        <el-date-picker class="circular-bead-date-picker" v-model="item.value"
                                            type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
                                        </el-date-picker>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </resources-item>
                    <resources-item title="材料附件" v-loading="loading">
                        <template #upload-btns>
                            <el-upload class="el-upload" :action="uploadUrl" :data="{ type: uploadSuffix.VIDEO }"
                                :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                                :before-upload="loadingStart" accept=".mp4">
                                <upload-btn iconType="video">视频</upload-btn>
                            </el-upload>
                            <el-upload class="el-upload" :action="uploadUrl " :data="{ type: uploadSuffix.AUDIO }"
                                :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                                :before-upload="loadingStart" accept=".mp3">
                                <upload-btn iconType="music">音频</upload-btn>
                            </el-upload>
                            <el-upload class="el-upload" :action="uploadUrl" :data="{ type: uploadSuffix.PACKAGE }"
                                :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                                :before-upload="loadingStart" accept=".zip,.rar">
                                <upload-btn iconType="file">压缩包</upload-btn>
                            </el-upload>
                            <el-upload class="el-upload" :action="uploadUrl" :data="{ type: uploadSuffix.DOCUMENT }"
                                :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                                :before-upload="loadingStart" accept=".doc,.docx,.wps">
                                <upload-btn iconType="text">文档</upload-btn>
                            </el-upload>
                            <el-upload class="el-upload" :action="uploadUrl" :data="{ type: uploadSuffix.PPT }"
                                :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                                :before-upload="loadingStart" accept=".ppt,.pptx">
                                <upload-btn iconType="ppt">PPT</upload-btn>
                            </el-upload>
                            <el-upload class="el-upload" :action="uploadUrl" :data="{ type: uploadSuffix.IMAGE }"
                                :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                                :before-upload="loadingStart" accept=".jpg,.png,.jpeg">
                                <upload-btn iconType="image">图片</upload-btn>
                            </el-upload>
                            <el-upload class="el-upload" :action="uploadUrl" :data="{ type: uploadSuffix.EXCLE }"
                                :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                                :before-upload="loadingStart" accept=".xls,.xlsx">
                                <upload-btn iconType="excle">Excle</upload-btn>
                            </el-upload>
                        </template>
                        <template>
                            <div class="edit-ul" v-if="fileList.length">
                                <div class="edit-li" v-for="(item, index) of fileList" :key="index">
                                    <div class="edit-li-no">{{index + 1}}</div>
                                    <div class="edit-li-content">
                                        <div class="file-icon">
                                            <img v-if="item.icon_type === 'video'" src="../../assets/images/res/video-color.png">
                                            <img v-else-if="item.icon_type === 'audio'" src="../../assets/images/res/music-color.png"  style="margin-bottom:2px;">
                                            <img v-else-if="item.icon_type === 'package'" src="../../assets/images/res/file-color.png">
                                            <img v-else-if="item.icon_type === 'document'"  src="../../assets/images/res/text-color.png" style="margin-bottom:3px;" >
                                            <img v-else-if="item.icon_type === 'ppt'" src="../../assets/images/res/ppt-color.png">
                                            <img v-else-if="item.icon_type === 'image'" src="../../assets/images/res/image-color.png">
                                            <img v-else-if="item.icon_type === 'excle'" src="../../assets/images/res/excle-color.png">
                                            <img v-else src="../../assets/images/res/text-color.png" style="margin-bottom:2px;" >
                                        </div>
                                        {{item.name}}
                                        <span class="size">({{item.size | fileSizeFormat}})</span>
                                    </div>
                                    <div class="edit-li-del" @click="handleDeleteFile(index)">
                                        <div class="line"></div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </resources-item>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '../../core';
import _ from '@kaobeimao/core';
import ResourcesItem from '@/components/resources/ResourcesItem';
import UploadBtn from '@/components/resources/UploadBtn.jsx';
import { deepCopy } from '@/core/utils';

export default {
    components: {
        ResourcesItem,
        UploadBtn
    },
    data() {
        return {
            loading: false,
            editForm: {
                id: '',
                name: '',
                topic: '',
                template_type: 1,
                type: 1,
                labels: [],
                config: Core.Utils.deepCopy(
                    Core.Const.RESOURCE_CONFIG.ACTIVITY
                ),
                dirId: '',
                sort: '',
            },
            fileList: [],
            labelList: Core.Const.RESOURCE.LABEL_MAP_LIST,
            typeList: Core.Const.RESOURCE.TYPE_MAP_LIST,
            templateTypeList: Core.Const.RESOURCE.TEMPLATE_TYPE_MAP_LIST,
            uploadUrl: Core.Const.NET.FILE_UPLOAD_END_POINT,
            uploadSuffix: Core.Const.UPLOAD_FILE_SUFFIX,
            downloadUrl: Core.Const.NET.FILE_URL_PREFIX,
            dirProps: {
                emitPath: false,
                label: 'name',
                value: 'id',
                checkStrictly: true,
            },
            dirTree: []
        };
    },
    created() {
        this.initDirTree();
        this.editForm.id = this.$route.query.id;
        this.init();
    },
    methods: {
        initDirTree() {
            Core.Api.ResourceDir.tree().then((res) => {
                this.dirTree = res.list
            });
        },
        // 初始化
        async init() {
            try {
                if (this.editForm.id) {
                    // 有id表示编辑，进行数据还原
                    const { resource } = await this.getResourceDetail();
                    this.editForm.name = resource.name;
                    this.editForm.topic = resource.topic;
                    this.editForm.dirId = resource.dir_id;
                    this.editForm.sort = resource.sort;
                    this.editForm.template_type = resource.template_type;
                    this.editForm.type = resource.type;
                    this.editForm.labels = resource.labels
                        ? resource.labels.split(',')
                        : [];
                    this.editForm.config = JSON.parse(resource.config);
                    this.fileList = JSON.parse(resource.files);
                }
            } catch (error) {
                console.log(error);
            }
        },
        // 附件上传成功的回调
        handleAddFile(res, file, fileList) {
            this.fileList.push({
                name: file.name,
                size: file.size,
                path:
                    this.downloadUrl +
                    Core.Utils.fileTypeFilter(file.name) +
                    '/' +
                    file.response.data.name,
                type: file.raw.type,
                icon_type: Core.Utils.fileTypeFilter(file.name)
            });
            this.loadingStop();
        },
        loadingStart() {
            this.loading = true;
        },
        loadingStop() {
            this.loading = false;
        },
        // 获取当前资源详情
        getResourceDetail() {
            return Core.Api.Resource.detail(this.editForm.id);
        },
        // 保存资源
        handleSave() {
            if (!this.editForm.dirId) {
                this.$message.warning('请选择资源所属的文件夹')
                return
            }
            Core.Api.Resource.save(
                this.editForm.id,
                this.editForm.template_type,
                this.editForm.type,
                _.U.toString(this.editForm.labels) || '',
                this.editForm.name,
                JSON.stringify(this.editForm.config),
                JSON.stringify(this.fileList),
                this.editForm.topic,
                this.editForm.dirId,
                this.editForm.sort,
            ).then(res => {
                this.$message.success('保存成功!');
                if (this.editForm.id) {
                    this.$router.replace({
                        path: `/resources/resources-view?id=${this.editForm.id}`
                    });
                } else {
                    this.$router.go(-1);
                }
            });
        },
        // 删除li
        handleDeleteLi(arr, index) {
            this.$delete(arr, index);
        },
        // 新增li
        handleLiAdd(item) {
            item.content.push({
                value: ''
            });
        },
        // 新增教学内容
        handleAddLiJx(item) {
            item.push({
                title: '',
                list: [
                    { name: '教学过程', value: '', type: 'input' },
                    { name: '学习重点', value: '', type: 'input' },
                    { name: '教学材料', value: '', type: 'input' }
                ]
            });
        },
        // 删除教学内容
        handleDeleteLiJx(arr, index) {
            this.$delete(arr, index);
        },
        // 标签改变
        handleChangeLabel(label) {
            if (this.editForm.labels.indexOf(label) === -1) {
                this.editForm.labels.push(label);
            } else {
                this.editForm.labels = this.editForm.labels.filter(
                    (item) => item !== label
                );
            }
        },
        // 新增标签
        handleAddLabel() {
            this.$prompt('请输入标签', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            }).then(({ value }) => {
                this.editForm.labels.push(value);
            });
        },
        // 移除标签
        handleRemoveLabel(index) {
            this.$delete(this.editForm.labels, index);
        },
        // 删除材料附件
        handleDeleteFile(index) {
            this.$delete(this.fileList, index);
        },
        // 模版类型改变
        handleTemplateTypeChange() {
            const templateType = this.editForm.template_type;
            this.initEditForm();
            this.editForm.config = null;
            if (templateType == 1) {
                this.editForm.config = deepCopy(Core.Const.RESOURCE_CONFIG.ACTIVITY);
            } else if (templateType == 2) {
                this.editForm.config = deepCopy(Core.Const.RESOURCE_CONFIG.GAME);
            } else if (templateType == 3) {
                this.editForm.config = deepCopy(Core.Const.RESOURCE_CONFIG.THREE);
            }
        },
        // 切换模版类型时重置公共部分表单
        initEditForm() {
            this.editForm.type = 1;
            this.editForm.name = '';
            this.editForm.topic = '';
            this.editForm.dirId = '';
            this.editForm.labels.length = 0;
        },
        handleCancel() {
            //取消编辑
            this.$confirm('确定要取消本次编辑吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$router.go(-1);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    color: #a6adb4;
    .edit {
        width: 80%;
        margin: 0 auto;
        margin-top: 20px;
        .header {
            @include flex(row, space-between, center);
            margin-bottom: 14px;
            .left {
                font-size: 20px;
                font-weight: 500;
                color: rgba(0, 0, 0, 1);
                @include ftb;
                span {
                    margin-right: 14px;
                }
            }
        }
        .edit-container {
            border-radius: 6px;
            margin-bottom: 30px;
            .edit-header {
                width: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(251, 252, 253, 1) 0%,
                    rgba(246, 248, 250, 1) 100%
                );
                border-radius: 6px 6px 0 0;
                .floor {
                    width: 100%;
                    height: 60px;
                    box-sizing: border-box;
                    padding: 0 34px;
                    border-radius: 6px 6px 0px 0px;
                    @include flex(row, space-between, center);
                    .left {
                        flex: 1;
                        span {
                            margin-right: 14px;
                        }
                    }
                    .right {
                        flex: 1;
                        @include flex(row, flex-start, center);
                        span {
                            margin-right: 14px;
                        }
                        .tag {
                            min-width: 60px;
                            height: 32px;
                            line-height: 32px;
                            padding: 0 10px;
                            text-align: center;
                            background: rgba(255, 255, 255, 0.5);
                            border-radius: 17px;
                            border: 1px solid #e7edf0;
                            color: #323538;
                            margin-right: 14px;
                            cursor: pointer;
                            &.active {
                                background: rgba(220, 111, 124, 0.2);
                                color: #e06d7b;
                                border-color: rgba(220, 111, 124, 0.2);
                            }
                            &.btn {
                                width: 40px;
                                padding: 0;
                            }
                        }
                        .add-tag-btn {
                            padding: 5px 9px;
                            border-radius: 50%;
                        }
                    }
                }
            }
            .edit-content {
                width: 100%;
                box-sizing: border-box;
                padding-bottom: 30px;
                background-color: #fff;
                .edit-ul {
                    width: 100%;
                    border-radius: 4px;
                    // border: 1px solid rgba(234, 238, 241, 1);
                    .no {
                        width: 54px;
                        height: 44px;
                        text-align: center;
                        line-height: 44px;
                        background: #f9fafc;
                        font-size: 20px;
                        color: #dc6f7c;
                        font-weight: 600;
                        border-radius: 4px;
                        position: relative;
                        margin-top: 20px;
                        &.arrow {
                            &::after {
                                content: '';
                                position: absolute;
                                right: -13px;
                                bottom: 0;
                                width: 0;
                                height: 0;
                                border-left: 13px solid rgba(249, 250, 252, 1);
                                border-top: 22px solid transparent;
                                border-bottom: 22px solid transparent;
                            }
                        }
                        &.plus {
                            font-size: 24px;
                            cursor: pointer;
                        }
                    }
                    .edit-li {
                        width: 100%;
                        background: #f7f8f9;
                        position: relative;
                        @include flex(row, flex-start, center);
                        border-top: 1px solid #eaeef1;
                        &:first-of-type {
                            border: none;
                        }

                        .edit-li-no {
                            font-size: 18px;
                            width: 44px;
                            font-weight: 600;
                            color: #dc6f7c;
                            text-align: center;
                            @include flex(row, center, center);
                            &.plus {
                                font-size: 24px;
                                cursor: pointer;
                            }
                        }
                        .edit-li-content {
                            width: 70%;
                            color: #323538;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            // box-sizing: border-box;
                            // padding: 14px;
                            padding: 14px;
                            border-left: 1px solid #eaeef1;
                            @include flex(row, flex-start, center);
                            .file-icon {
                                @include ftb;
                                img {
                                    width: 14px;
                                    margin-right: 10px;
                                }
                            }
                            .size {
                                color: #9aa4ad;
                            }
                        }
                        .edit-li-del {
                            width: 22px;
                            height: 22px;
                            border: 1px solid rgba(24, 39, 64, 0.3);
                            border-radius: 50%;
                            cursor: pointer;
                            position: absolute;
                            top: 11px;
                            right: 20px;
                            @include flex(row, center, center);
                            .line {
                                width: 8px;
                                height: 1px;
                                background: rgba(24, 39, 64, 0.3);
                            }
                        }
                    }
                }
                .edit-ul-jx {
                    width: 100%;
                    .edit-li-jx {
                        width: 100%;
                        margin-bottom: 20px;
                        @include fj;
                        .no {
                            width: 54px;
                            height: 44px;
                            text-align: center;
                            line-height: 44px;
                            background: #f9fafc;
                            // border: 1px solid rgba(234, 238, 241, 1);
                            font-size: 20px;
                            color: #dc6f7c;
                            font-weight: 600;
                            // margin-left: 20px;
                            border-radius: 4px;
                            position: relative;
                            &.arrow {
                                &::after {
                                    content: '';
                                    position: absolute;
                                    right: -13px;
                                    bottom: 0;
                                    width: 0;
                                    height: 0;
                                    border-left: 13px solid #f9fafc;
                                    border-top: 22px solid transparent;
                                    border-bottom: 22px solid transparent;
                                }
                            }
                            &.plus {
                                font-size: 24px;
                                cursor: pointer;
                            }
                            // background: linear-gradient(-135deg, transparent 15px, #fff 0) top right,
                            //             linear-gradient(-45deg, transparent 15px, #fff 0) bottom right;
                            // background-size: 50% 50%; background-repeat: no-repeat;
                        }
                        .edit-li-jx-content {
                            width: calc(100% - 100px);
                            border-radius: 4px;
                            border: 1px solid rgba(234, 238, 241, 1);
                            .edit-li-jx-content-title {
                                width: 100%;
                                box-sizing: border-box;
                                padding: 0 14px;
                                height: 44px;
                                line-height: 44px;
                                background: rgba(249, 250, 252, 1);
                                font-size: 14px;
                                font-weight: 600;
                                color: rgba(50, 53, 56, 1);
                                border-bottom: 1px solid rgba(234, 238, 241, 1);
                                position: relative;
                                .edit-li-del {
                                    width: 22px;
                                    height: 22px;
                                    border: 1px solid rgba(24, 39, 64, 0.3);
                                    border-radius: 50%;
                                    cursor: pointer;
                                    position: absolute;
                                    top: 11px;
                                    right: 20px;
                                    @include flex(row, center, center);
                                    .line {
                                        width: 8px;
                                        height: 1px;
                                        background: rgba(24, 39, 64, 0.3);
                                    }
                                }
                            }
                            .edit-li-jx-item {
                                display: flex;
                                border-bottom: 1px solid rgba(234, 238, 241, 1);
                                &:last-of-type {
                                    border: none;
                                }
                                .edit-li-jx-item-title {
                                    width: 84px;
                                    text-align: center;
                                    background: rgba(249, 250, 252, 1);
                                    font-size: 12px;
                                    color: rgba(109, 116, 123, 1);
                                    @include flex(row, center, center);
                                    border-right: 1px solid
                                        rgba(234, 238, 241, 1);
                                }
                                .edit-li-jx-item-content {
                                    width: calc(100% - 84px);
                                    min-height: 44px;
                                    font-size: 12px;
                                    color: rgba(50, 53, 56, 1);
                                    box-sizing: border-box;
                                    padding: 14px;
                                    text-align: justify;
                                }
                            }
                        }
                    }
                }
                .edit-form {
                    width: 100%;
                    @include flex(row, flex-start, center);
                    flex-wrap: wrap;
                    .edit-form-item {
                        width: 50%;
                        margin-bottom: 20px;
                        @include ftb;
                        .left {
                            margin-right: 10px;
                        }
                        .right {
                            .input-area {
                                width: 500px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>