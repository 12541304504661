const UploadBtn = {
    name: 'upload-btn',
    props: {
        iconType: {
            type: String
        },
    },
    render() {
        const { iconType, $slots } = this;
        const { text: name } = $slots.default[0];
        const iconStyle = {
            width: '14px',
            marginRight: '5px'
        };
        return (
            <div class="edit-upload-btn">
                <img
                    style={iconStyle}
                    src={require(`../../assets/images/res/${iconType}-color.png`)}
                />
                <span>{name}+ </span>
            </div>
        );
    }
};

UploadBtn.install = function(Vue) {
    Vue.component(UploadBtn.name, UploadBtn);
};

export default UploadBtn;
